@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
  font-family: "MADE Mirage";
  src: url("./assets/fonts/Made-Mirage/MADE-Mirage-Black.otf")
    format("opentype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "MADE Mirage";
  src: url("./assets/fonts/Made-Mirage/MADE-Mirage-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "MADE Mirage";
  src: url("./assets/fonts/Made-Mirage/MADE-Mirage-Medium.otf")
    format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "MADE Mirage";
  src: url("./assets/fonts/Made-Mirage/MADE-Mirage-Regular.otf")
    format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "MADE Mirage";
  src: url("./assets/fonts/Made-Mirage/MADE-Mirage-Thin.otf") format("opentype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "GT Super Ds Trial";
  src: url("./assets/fonts/GT-Super/GT-Super-Display-Bold-Italic-Trial.otf")
    format("opentype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "GT Super Ds Trial";
  src: url("./assets/fonts/GT-Super/GT-Super-Display-Bold-Trial.otf")
    format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "GT Super Ds Trial";
  src: url("./assets/fonts/GT-Super/GT-Super-Display-Light-Italic-Trial.otf")
    format("opentype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "GT Super Ds Trial";
  src: url("./assets/fonts/GT-Super/GT-Super-Display-Light-Trial.otf")
    format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "GT Super Ds Trial";
  src: url("./assets/fonts/GT-Super/GT-Super-Display-Medium-Italic-Trial.otf")
    format("opentype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "GT Super Ds Trial";
  src: url("./assets/fonts/GT-Super/GT-Super-Display-Medium-Trial.otf")
    format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "GT Super Ds Trial";
  src: url("./assets/fonts/GT-Super/GT-Super-Display-Regular-Italic-Trial.otf")
    format("opentype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "GT Super Ds Trial";
  src: url("./assets/fonts/GT-Super/GT-Super-Display-Regular-Trial.otf")
    format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "GT Super Ds Trial";
  src: url("./assets/fonts/GT-Super/GT-Super-Display-Super-Italic-Trial.otf")
    format("opentype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "GT Super Ds Trial";
  src: url("./assets/fonts/GT-Super/GT-Super-Display-Super-Trial.otf")
    format("opentype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "GT Super Ds Trial";
  src: url("./assets/fonts/GT-Super/GT-Super-Text-Black-Italic-Trial.otf")
    format("opentype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "GT Super Ds Trial";
  src: url("./assets/fonts/GT-Super/GT-Super-Text-Black-Trial.otf")
    format("opentype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "GT Super Ds Trial";
  src: url("./assets/fonts/GT-Super/GT-Super-Text-Bold-Italic-Trial.otf")
    format("opentype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "GT Super Ds Trial";
  src: url("./assets/fonts/GT-Super/GT-Super-Text-Bold-Trial.otf")
    format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "GT Super Ds Trial";
  src: url("./assets/fonts/GT-Super/GT-Super-Text-Book-Italic-Trial.otf")
    format("opentype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "GT Super Ds Trial";
  src: url("./assets/fonts/GT-Super/GT-Super-Text-Book-Trial.otf")
    format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "GT Super Ds Trial";
  src: url("./assets/fonts/GT-Super/GT-Super-Text-Medium-Italic-Trial.otf")
    format("opentype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "GT Super Ds Trial";
  src: url("./assets/fonts/GT-Super/GT-Super-Text-Medium-Trial.otf")
    format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "GT Super Ds Trial";
  src: url("./assets/fonts/GT-Super/GT-Super-Text-Regular-Italic-Trial.otf")
    format("opentype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "GT Super Ds Trial";
  src: url("./assets/fonts/GT-Super/GT-Super-Text-Regular-Trial.otf")
    format("opentype");
  font-weight: 400;
  font-style: normal;
}
